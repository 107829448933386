export const mdOptions = {
    linkAttributes: {
        pattern: /^https?:\/\//,
        attrs: {
            target: "blank",
            rel: "noopener noreferrer",
        },
    },
};

const defaults = {
    title: "Acronis Advisory Database - Acronis",
    description: "Acronis Advisory Database. Find information about the latest security advisories and updates for Acronis products.",
}

export default {
    reset() {
        this.setTitle();
        this.setDescription(defaults.description);
    },

    setTitle(...s: string[]) {
        s = s || [];
        s.push(defaults.title);
        document.title = s.join(" - ");
    },

    setDescription(s: string) {
        document
            .querySelector('meta[name="description"]')!
            .setAttribute("content", s);
    },

    debounce(func: CallableFunction, wait = 500) {
        let timeout: number;
        return function executedFunction(...args: any) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    },

    parseQuery(q: string): Map<string, string[]> {
        q = q.replaceAll("'", '"');
        const re = /(?<key>-?[a-z.]+):(?<val>([^ "]+)|("[^"]+"))/g;
        const kv = new Map<string, string[]>();
        for (const m of q.matchAll(re)) {
            const vals = kv.get(m[1]) || [];
            vals.push(m[2].replaceAll('"', ""));
            kv.set(m[1], vals);
        }
        return kv;
    }
}
