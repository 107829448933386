
















import Vue from "vue";

export default Vue.extend({
  name: "SkeletonSearch",
  components: {},
  data: () => ({}),
  methods: {},
});
