import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { Notification } from "@acronis/ui-kit";

import router from "@/router";

const API_URL = document.location.origin;

axios.defaults.baseURL = API_URL + "/api/v1";
axios.defaults.withCredentials = false;

function encode(opts: any): string {
    const q = new URLSearchParams();
    for (const [key, val] of Object.entries(opts)) {
        if (val) {
            q.set(key, String(val));
        }
    }
    return q.toString();
}

export default {
    getAdvisory(id: string) {
        return axios.get(`/advisories/${id}`);
    },

    searchAdvisories(opts: any) {
        return axios.get("/advisories?" + encode(opts));
    },

    getUpdate(id: string) {
        return axios.get(`/updates/${id}`);
    },

    searchUpdates(opts: any) {
        return axios.get("/updates?" + encode(opts));
    },
};

axios.interceptors.response.use(
    function (response: AxiosResponse) {
        return response;
    },

    function (error: AxiosError) {
        if (!error.response) {
            Notification.error({
                title: "Network Error",
                message: error.message,
            });
        }
        else if (error.response.status == 404) {
            router.push("/notfound");
        }
        return Promise.reject(error);
    }
);
