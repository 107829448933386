<template>
  <div>
    <router-link
      v-for="item of items"
      :key="item.id"
      class="search-item"
      :to="'/' + item.id"
    >
      <div class="search-item-date">
        {{ item.id }} &bull; published
        <el-tooltip :content="item.published | date">
          <span class="tooltipped">{{ item.published | reldate }}</span>
        </el-tooltip>
      </div>
      <div class="search-item-summary">
        {{ item.summary }}
      </div>
      <TagsUpdate :update="item" />
    </router-link>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import TagsUpdate from "@/components/TagsUpdate.vue";

export default Vue.extend({
  components: {
    TagsUpdate,
  },
  data: () => ({}),
  props: {
    items: Array,
  },
});
</script>

<style lang="css">
</style>
