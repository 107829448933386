





















































































































import Vue from "vue";

export default Vue.extend({
  name: "App",
  components: {},
  data: () => ({
    links: {
      social: [
        {
          text: "Acronis on X",
          link: "https://twitter.com/Acronis",
          icon: "/img/twitter.svg",
        },
        {
          text: "Acronis Blog",
          link: "https://www.acronis.com/blog",
          icon: "/img/acronis-blog.svg",
        },
        {
          text: "Acronis on YouTube",
          link: "https://www.youtube.com/user/Acronis",
          icon: "/img/youtube.svg",
        },
        {
          text: "Acronis on LinkedIn",
          link: "https://www.linkedin.com/company/acronis",
          icon: "/img/linkedin.svg",
        },
        {
          text: "Acronis on Spiceworks",
          link: "https://community.spiceworks.com/pages/acronis",
          icon: "/img/spiceworks.svg",
        },
        {
          text: "Acronis on Reddit",
          link: "https://www.reddit.com/r/acronis",
          icon: "/img/reddit.svg",
        },
        {
          text: "Acronis on Facebook",
          link: "https://www.facebook.com/acronis",
          icon: "/img/facebook.svg",
        },
      ],
    },
  }),
  computed: {
    year(): number {
      return new Date().getFullYear();
    },
  },
  methods: {
    section(): string {
      return this.$route.fullPath.split("/")[1].replaceAll("/", "");
    },
  },
});
