




















import Vue from "vue";

import * as types from "@/types";

export default Vue.extend({
  name: "TagsUpdate",
  components: {},
  data: () => ({}),
  props: {
    update: {
      type: types.Update,
      required: true,
    },
  },
  methods: {},
});
