













































































































import Vue from "vue";
import vPagination from "vue-plain-pagination";
import SkeletonSearch from "@/components/SkeletonSearch.vue";

import * as types from "@/types";
import utils from "@/utils";

export default Vue.extend({
  name: "Search",
  components: {
    vPagination,
    SkeletonSearch,
  },
  data: () => ({
    query: "",
    searchFiltersVisible: false,
    labels: {
      pagination: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    },
  }),
  props: {
    placeholder: String,
    filters: Array,
    sort: Object,
    data: Object,
    loading: Boolean,
  },
  created() {
    this.query = this.$query.q;
    const query = utils.parseQuery(this.query);
    for (const f of this.filters as Array<types.Filter>) {
      Object.assign(f, { value: query.get(f.label) });
    }
    if (this.$query.sort) {
      for (const s of this.sort.options) {
        if (s.value == this.$query.sort) {
          this.sort.value = s;
          break;
        }
      }
    } else {
      this.sort.value = this.sort.options[0];
    }
  },
  methods: {
    toggleSearchFilters() {
      this.searchFiltersVisible = true;
    },

    updateSearchFilters() {
      this.searchFiltersVisible = false;
      let q = "";
      for (const f of this.filters as Array<types.Filter>) {
        if (f.value.length) {
          f.value.forEach((v: string) => {
            if (v.includes(" ")) {
              v = `"${v}"`;
            }
            q += ` ${f.label}:${v}`;
          });
        }
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: "1",
          q: q,
        },
      });
    },

    updateQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: "1",
          q: this.query,
        },
      });
    },

    updateSort(f: types.Option) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: "1",
          sort: f.value,
        },
      });
    },

    resetSearch() {
      this.$router.push({ path: this.$route.path });
    },
  },
});
