

























































































































import Vue from "vue";
import MarkdownItVue from "markdown-it-vue";
import SkeletonArticle from "@/components/SkeletonArticle.vue";
import TagsAdvisory from "@/components/TagsAdvisory.vue";
import ListUpdate from "@/components/ListUpdate.vue";

import api from "@/api";
import utils from "@/utils";
import * as types from "@/types";
import mdOptions from "@/utils";

export default Vue.extend({
  components: {
    MarkdownItVue,
    SkeletonArticle,
    TagsAdvisory,
    ListUpdate,
  },
  data: () => ({
    loading: true,
    advisory: new types.Advisory(),
    updates: new types.PagedResponse(),
    options: mdOptions,
  }),
  methods: {
    metaDescription(adv: types.Advisory): string {
      let d = `${adv.id} - `;
      if (adv.cve) {
        d += `${adv.cve} - `;
      }
      d += `${adv.severity.name} severity - ${adv.summary}. `;
      if (adv.products) {
        const products = adv.products
          .map((p) => {
            return p.name;
          })
          .join(", ");
        d += `Security advisory affects ${products}. `;
      }
      return d;
    },
  },
  mounted() {
    api.getAdvisory(this.$route.params.id).then((response) => {
      this.advisory = new types.Advisory(response.data);
      if (this.$route.params.id != this.advisory.id) {
        this.$router.push(`/${this.advisory.id}`);
      }
      utils.setTitle(this.advisory.summary, this.advisory.id);
      utils.setDescription(this.metaDescription(this.advisory));

      api
        .searchUpdates({
          q: `advisory:${this.advisory.id}`,
          sort: "-published -id",
        })
        .then((response) => {
          this.updates = response.data;
          this.updates.items.forEach((value, i) => {
            this.updates.items[i] = new types.Update(value);
          });
          this.loading = false;
        });
    });
  },
  destroyed() {
    utils.reset();
  },
});
