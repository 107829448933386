import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import UIKit from "@acronis/ui-kit";

import "@acronis/ui-kit/lib/ui-kit.min.css";
import Skeleton from "vue-loading-skeleton";
import QuerySynchronizer from "@oarepo/vue-query-synchronizer";

dayjs.extend(relativeTime);

Vue.use(UIKit);
Vue.use(Skeleton);
Vue.use(QuerySynchronizer, {
  router: router
})
Vue.config.productionTip = false;

Vue.filter("date", (ds: string) => {
  if (!ds) return "Invalid Date";
  const t = ds.includes("T");
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: t ? "numeric" : undefined,
    minute: t ? "numeric" : undefined,
    timeZone: "UTC",
    timeZoneName: t ? "short" : undefined,
  }).format(new Date(ds));
});

Vue.filter("reldate", (ds: string) => {
  if (!ds) return "Invalid Date";
  return dayjs(ds).fromNow();
});

Vue.filter("capitalize", (s: string) => {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
});

Vue.mixin({
  data: () => ({}),
  methods: {},
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
