import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Advisory from "@/views/Advisory.vue";
import AdvisorySearch from "@/views/AdvisorySearch.vue";
import Update from "@/views/Update.vue";
import UpdateSearch from "@/views/UpdateSearch.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const idSEC = ":id(SEC-\\d+)";
const idCVE = ":id(CVE-\\d+-\\d+)";
const idUPD = ":id(UPD-[0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4})";

const searchMeta = {
  query: {
    "q": "string:",
    "page": "int:1",
    "limit": "int:25",
    "sort": "string:",
  }
}

const routes: Array<RouteConfig> = [
  // default to advisories page for now
  {
    path: "/",
    redirect: "/advisories",
  },
  // advisories
  {
    path: "/advisories",
    component: AdvisorySearch,
    meta: searchMeta,
  },
  // advisory by SEC ID
  {
    path: `/${idSEC}`,
    redirect: "/advisories/:id",
  },
  {
    path: `/advisories/${idSEC}`,
    component: Advisory,
  },
  // advisory by CVE ID
  {
    path: `/${idCVE}`,
    redirect: "/advisories/:id",
  },
  {
    path: `/advisories/${idCVE}`,
    component: Advisory,
  },
  // security updates
  {
    path: "/updates",
    component: UpdateSearch,
    meta: searchMeta,
  },
  {
    path: `/${idUPD}`,
    redirect: "/updates/:id",
  },
  {
    path: `/updates/${idUPD}`,
    component: Update,
  },
  // everything else will result in 404
  {
    path: "**",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

export default router;
