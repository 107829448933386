class Base {
    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class Filter extends Base {
    label!: string;
    value!: string[];
    options!: any[];
    filterable!: boolean;
}

export class Option extends Base {
    label!: string;
    value!: string;
}

export class PagedResponse extends Base {
    limit!: number;
    offset!: number;
    total!: number;
    items!: any[];
}

export class Advisory extends Base {
    id!: string;
    cve!: string;
    cwes!: string[];
    summary!: string;
    severity!: Severity;
    products!: Product[];
    description!: string;
    references!: Reference[];
    credits!: Reference[];
    published!: string;
    updated?: string;
}

export class Severity extends Base {
    name!: string;
    cvss!: CVSS;
}

export class CVSS extends Base {
    score!: number;
    vector!: string;
}

export class Product extends Base {
    name!: string;
    patched!: number;
    platforms!: string[];
}

export class Reference extends Base {
    text!: string;
    link!: string;
}

export class Update extends Base {
    id!: string;
    summary!: string;
    description!: string;
    product!: Product;
    platforms!: string[];
    advisories!: string[];
    references!: Reference[];
    public!: boolean;
    published!: string;
    updated?: string;
}

export const filterProduct = {
    label: "product",
    value: [],
    options: [
        // ACI
        "Acronis Cyber Infrastructure",

        // Cloud
        "Acronis Cyber Protect Cloud Agent",

        // On-prem
        "Acronis Cyber Backup 12.5",
        "Acronis Cyber Protect 15",
        "Acronis Cyber Protect 16",
        "Acronis Snap Deploy",
        "Acronis Cloud Manager",

        // Home
        "Acronis True Image 2020",
        "Acronis True Image 2021",
        "Acronis Cyber Protect Home Office",
        "Acronis VSS Doctor",

        // Plugins
        "Acronis Backup extension for Plesk",
        "Acronis Backup plugin for DirectAdmin",
        "Acronis Backup plugin for cPanel & WHM",
    ],
    filterable: true,
};

export const filterPlatform = {
    label: "platform",
    value: [],
    options: ["Windows", "macOS", "Linux"],
};

export const filterSeverity = {
    label: "severity",
    value: [],
    options: ["critical", "high", "medium", "low"],
};
