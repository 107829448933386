












import Vue from "vue";
import Search from "@/components/Search.vue";
import ListUpdate from "@/components/ListUpdate.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Search,
    ListUpdate,
  },
  data: () => ({
    data: new types.PagedResponse(),
    loading: true,
    filters: [types.filterProduct, types.filterPlatform],
    sort: {
      value: new types.Option(),
      options: [
        {
          label: "Newest",
          value: "-published -id",
        },
        {
          label: "Oldest",
          value: "published -id",
        },
      ],
    },
  }),
  mounted() {
    this.loading = true;
    api
      .searchUpdates(this.$query)
      .then((response) => {
        this.data = response.data;
        this.data.items.forEach((value, i) => {
          this.data.items[i] = new types.Update(value);
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
