













































import Vue from "vue";

import * as types from "@/types";

export default Vue.extend({
  name: "TagsAdvisory",
  components: {},
  data: () => ({
    type: {
      info: "info",
      low: "success",
      medium: "warning",
      high: "critical",
      critical: "danger",
    },
  }),
  props: {
    advisory: {
      type: types.Advisory,
      required: true,
    },
    compact: Boolean,
  },
  methods: {
    products() {
      const items: string[] = [];
      if (this.advisory.products) {
        for (const p of this.advisory.products) {
          items.push(p.name);
        }
      }
      return this.unique(items);
    },

    platforms() {
      const items: string[] = [];
      if (this.advisory.products) {
        for (const p of this.advisory.products) {
          items.push(...p.platforms);
        }
      }
      return this.unique(items);
    },

    unique(items: string[]) {
      return Array.from(new Set(items)).sort();
    },
  },
});
