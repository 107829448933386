


























































































import Vue from "vue";
import MarkdownItVue from "markdown-it-vue";
import SkeletonArticle from "@/components/SkeletonArticle.vue";
import TagsUpdate from "@/components/TagsUpdate.vue";
import ListAdvisory from "@/components/ListAdvisory.vue";

import api from "@/api";
import utils from "@/utils";
import * as types from "@/types";
import mdOptions from "@/utils";

export default Vue.extend({
  components: {
    MarkdownItVue,
    SkeletonArticle,
    TagsUpdate,
    ListAdvisory,
  },
  data: () => ({
    loading: true,
    update: new types.Update(),
    advisories: new types.PagedResponse(),
    options: mdOptions,
  }),
  methods: {
    metaDescription(upd: types.Update): string {
      let d = `${upd.id} - `;
      d += `${upd.summary} for ${upd.platforms.join(", ")} includes ${
        upd.advisories.length
      } security advisor${upd.advisories.length == 1 ? "y" : "ies"}.`;
      return d;
    },
  },
  mounted() {
    api.getUpdate(this.$route.params.id).then((response) => {
      this.update = new types.Update(response.data);
      if (this.$route.params.id != this.update.id) {
        this.$router.push(`/${this.update.id}`);
      }
      utils.setTitle(this.update.summary);
      utils.setDescription(this.metaDescription(this.update));

      if (this.update.public) {
        api
          .searchAdvisories({
            q: this.update.advisories.map((item) => `id:${item}`).join(" "),
            sort: "-cvss -published -id",
          })
          .then((response) => {
            this.advisories = response.data;
            this.advisories.items.forEach((value, i) => {
              this.advisories.items[i] = new types.Advisory(value);
            });
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    });
  },
  destroyed() {
    utils.reset();
  },
});
